@use "src/styles/color";
@use "src/styles/breakpoint";
@use "src/styles/fontweight";

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(25px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.hero {
  min-height: 40vh;
  padding-bottom: 50px;
  padding-top: 50px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  margin-left: -20px;
  margin-right: -20px;
  margin-top: -30px;
  background-color: color.$blue-100;
  @media (min-width: breakpoint.$md) {
    margin-left: -60px;
    margin-right: -60px;
    margin-top: -60px;
    min-height: 60vh;
  }
  h1 {
    font-size: 2.8125rem;
    font-weight: fontweight.$semi_bold;
    line-height: 1.4;
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    color: color.$white;
    text-align: center;
    margin: 0 auto 50px;
    animation: slideUp 0.3s ease-out;

    @media (min-width: breakpoint.$sm) {
      font-size: 3rem;
    }

    @media (min-width: breakpoint.$md) {
      font-size: 4rem;
      padding-right: 25px;
      padding-left: 25px;
    }
    @media (min-width: breakpoint.$lg) {
      font-size: 4.625rem;
    }
    @media (min-width: breakpoint.$xl) {
      max-width: 1140px;
    }
  }

  .buttonContainer {
    justify-content: center;
    flex-wrap: wrap;
    display: flex;
  }

  .buttonLink {
    animation: fadeIn 0.3s ease-in-out;
    animation-fill-mode: both;
    margin: 0 10px 20px !important;
    @media (min-width: breakpoint.$md) {
      margin: 0 15px 20px !important;
    }
  }
}
