@use "src/styles/breakpoint";
@use "src/styles/color";
@use "src/styles/fontweight";

.featuredPodcast {
  display: flex;
  position: relative;
  align-items: flex-end;
  min-height: 511px;
  padding: 30px 0;
  color: color.$white;
  margin: -30px -20px 0;
  @media (min-width: breakpoint.$md) {
    margin: -60px -60px 0;
    align-items: center;
    min-height: 651px;
    padding: 100px 0;
  }
}

.featuredPodcastContainer {
  z-index: 3;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  position: relative;
  @media (min-width: breakpoint.$md) {
    padding-right: 60px;
    padding-left: 60px;
  }
  @media (min-width: breakpoint.$xl) {
    max-width: 1140px;
  }
}

.featuredPodcastRow {
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
  margin-left: -20px;
  @media (min-width: breakpoint.$md) {
    margin-right: -25px;
    margin-left: -25px;
  }
}

.featuredPodcastContent {
  position: relative;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  flex: 0 0 100%;
  max-width: 100%;
  @media (min-width: breakpoint.$md) {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    padding-right: 25px;
    padding-left: 25px;
  }
}

.backgroundImage {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  left: 0;
  top: 0;
  z-index: -1;
}

.imageOverlay {
  z-index: 2;
  background-color: rgba(color.$black, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.preHeading {
  font-size: 16px;
  margin-bottom: 0;
}

.heading {
  font-size: 30px;
  margin-bottom: 0;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  @media (min-width: breakpoint.$md) {
    font-size: 93.75px;
    line-height: 90px;
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}

.body {
  font-size: 16px;
  margin-bottom: 0;
  @media (min-width: breakpoint.$md) {
    font-size: 30px;
    font-weight: fontweight.$semi_bold;
  }
}

.buttonContainer {
  margin-top: 30px;
  @media (min-width: breakpoint.$md) {
    margin-top: 50px;
  }
}

.picture {
  z-index: 2;
}
